<template>
  <div class="app-container">
    <div class="flex">
      <div class="top" style="margin: 10px 10px 10px 0">
        <el-button
          type="success"
          :disabled="selecTionArr.length < 1"
          icon="el-icon-plus"
          @click="showEdit(selecTionArr[0])"
          >编辑经营商品</el-button
        >
        <el-button
          type="primary"
          :disabled="selecTionArr.length !== 1"
          icon="el-icon-edit"
          @click="balanceSet(selecTionArr[0])"
          >秤具推送设置</el-button
        >
      </div>
      <div class="search flex-2" style="margin: 10px 0">
        <el-input
          clearable
          placeholder="按商户姓名查询"
          v-model="searchQuery.merchantPersonnelName"
          class="searchInput"
        >
          <template slot="prepend">商户姓名</template>
        </el-input>
        <el-input
          clearable
          placeholder="按摊位编号查询"
          v-model="searchQuery.stallNo"
          class="searchInput"
        >
          <template slot="prepend">摊位编号</template>
        </el-input>
      </div>
    </div>
    <el-table
      :data="listData"
      style="width: 100%; margin-bottom: 20px; text-align: center"
      border
      default-expand-all
      @selection-change="getSelection($event)"
      @row-dblclick="showEdit($event)"
    >
      <el-table-column
        type="selection"
        width="55"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="merchantPersonnelName"
        label="商户姓名"
        width="180"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="stallNo"
        label="摊位编号"
        width="180"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="businessType"
        label="经营类别"
        width="180"
        align="center"
      ></el-table-column>
      <el-table-column label="商品名称" align="center">
        <template slot-scope="scope">
          <span v-for="(item, index) in scope.row.commodityList" :key="index">{{
            item.name + ","
          }}</span>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      v-bind="searchQuery"
      :layout="layout"
      @size-change="onSizeChange"
      @current-change="onPageChange"
    />
    <el-dialog
      :title="'编辑经营商品'"
      :visible.sync="dialogVisible"
      width="800px"
      center
      :close-on-click-modal="false"
    >
      <el-form
        ref="ruleForm"
        :model="form"
        :inline="true"
        label-width="120px"
        :rules="rules"
      >
        <!-- <el-form-item label="商户名称">
          <el-input v-model="form.merchantPersonnelName" disabled />
        </el-form-item> -->
        <el-form-item label="选择商品" prop="newCommodity">
          <el-select
            v-model="form.newCommodity"
            filterable
            placeholder="请选择或搜索商品"
          >
            <el-option
              v-for="item in commodityListArr"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
          <el-button @click="addCommodity" class="ml20">新增</el-button>
        </el-form-item>

        <el-form-item
          v-for="(items, indexs) in form.commodityList"
          :key="indexs"
        >
          <div class="addWrap">
            <el-input
              placeholder="请输入单价"
              v-model="items.unitPrice"
              class="partInput"
            >
              <template slot="prepend">
                <span class="partName">{{ items.name }}</span>
              </template>
              <el-button slot="append" class="partBtn"
                >元/{{ items.unitName }}</el-button
              >
              <el-button
                slot="append"
                @click="deleteUnit(indexs)"
                icon="el-icon-delete"
              ></el-button>
            </el-input>
          </div>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="batchHandleEdit('ruleForm')"
          >确 定</el-button
        >
      </span>
    </el-dialog>

    <el-dialog
      :title="'编辑秤具按键'"
      :visible.sync="balanceSetShow"
      width="800px"
      center
    >
      <el-form ref="ruleForm" :model="form" :inline="true" label-width="120px">
        <el-form-item
          v-for="(items, indexs) in form.commodityList"
          :key="indexs"
        >
          <div class="addWrap">
            <el-input
              placeholder="请输入单价"
              v-model="items.buttonNo"
              :maxlength="1"
              class="partInput"
            >
              <template slot="prepend">
                <span class="partName">{{ items.name }}</span>
              </template>
              <el-button slot="append" class="partBtn_key">号按键</el-button>
            </el-input>
          </div>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="balanceSetShow = false">取 消</el-button>
        <el-button type="primary" @click="handleEdit('ruleForm')"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import api from "@/api/common";

export default {
  data() {
    return {
      rules: {
        newCommodity: [
          //商品验证
          { required: true, message: "请输入商品名", trigger: "blur" },
        ],
      },
      layout: "sizes, prev, pager, next, jumper",
      selCommodityArr: [],
      listData: [],
      dialogVisible: false,
      commodityListArr: [],
      form: {},
      formArray:[],
      typeList: [],
      selecTionArr: [],
      balanceSetShow: false,
      balanceObj: {},
      numArr: [],
      searchQuery: {
        currentPage: 1,
        pageCount: 1,
        pageSize: 10,
        total: 0,
        stallNo: "",
        merchantPersonnelName: "",
      },
    };
  },
  watch: {
    searchQuery: {
      handler() {
        this.getDataList();
      },
      deep: true, //开启深度监听
    },
  },
  created() {
    this.getDataList();
    this.getcommodityList();
  },
  methods: {
    //监听设置编号,暂时还有一点问题

    getInputVal(e) {
      this.numArr = [];
      let arr = this.form.commodityList;

      arr.forEach((item) => {
        if (item.buttonNo > 0) {
          this.numArr.push(item.buttonNo);
        }
      });
      if (this.numArr.indexOf(e) !== -1) {
        this.$message({ message: "已存在该按键", type: "error" });
      }
    },
    //设置秤具按键
    balanceSet(obj) {
      this.form = obj;

      this.balanceSetShow = true;
    },

    async getDataList() {
      await api
        .list({ apiName: "/merchant/businessVariety", ...this.searchQuery })
        .then((res) => {
          this.listData = res.data.data;
          this.searchQuery.total = parseInt(res.data.total);
        });
      this.listData.forEach((item) => {
        this.typeList.push({ name: item.name, id: item.id });
      });
    },
    async getcommodityList() {
      await api
        .getAllCommodityList({ apiName: "/merchant/businessVariety" })
        .then((res) => {
          this.commodityListArr = res.data;
        });
    },
    getSelection(e) {
      console.log('eeee',e);
      
      this.selecTionArr = e;
    },
    showEdit(obj) {
      this.form = obj;
      this.dialogVisible = true;
    },
    addCommodity() {
      //判断下拉框是否有值
      if(this.form.newCommodity==undefined){
         this.$message.error("请选择商品！");
      }else{
        let newId = this.form.newCommodity;

      let formObj = this.form.commodityList.find((item) => item.id == newId);

      if (formObj !== undefined) {
        this.$message({ message: "已存在该产品", type: "error" });
        return;
      }
      let obj = this.commodityListArr.find((item) => item.id == newId);

      this.form.commodityList.push(obj);
      }
      
    },
    // 批量编辑经营类别
    batchHandleEdit(formName){
      let formArr = this.selecTionArr.map((item) => {
        return {
          merchantPersonnelId:item.merchantPersonnelId,
          commodityList:this.form.commodityList
        }
      })
      this.$refs[formName].validate((valid) => {  
        if (valid) {
          api.post({apiName:"/merchant/businessVariety/updateBatch",data:formArr}).then((res) => {
            if (res.code == 200) {
              this.$message({ message: "更新成功", type: "success" });
            } else {
              this.$message({ message: res.message, type: "error" });
            }
            this.getDataList();
            this.dialogVisible = false;
            this.balanceSetShow = false;
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    
    //单个编辑
    handleEdit(formName) {
      this.$refs[formName].validate((valid) => {  
        if (valid) {
          this.form.apiName = "/merchant/businessVariety";
          api.update(this.form).then((res) => {
            if (res.code == 200) {
              this.$message({ message: "更新成功", type: "success" });
            } else {
              this.$message({ message: res.message, type: "error" });
            }
            this.getDataList();
            this.dialogVisible = false;
            this.balanceSetShow = false;
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    showAdd() {
      this.handelType = "new";
      this.form = {
        newCommodity:""
      };
      this.dialogVisible = true;
    },
    onSizeChange(e) {
      this.searchQuery.pageSize = e;
      this.getDataList();
    },
    onPageChange(e) {
      this.searchQuery.currentPage = e;
      this.getDataList();
    },
    deleteUnit(index) {
      console.log(index);
      console.log(this.form.commodityList);
      this.form.commodityList.splice(index, 1);
    },
  },
};
</script>

<style lang="scss" scoped>
.search {
  display: flex;
}
.el-form-item label:after {
  content: "";
  display: inline-block;
  width: 100%;
}
.info-form label {
  font-weight: normal;
}
.el-form-item__label {
  text-align: justify;
  height: 50px;
}
.searchInput {
  // width: 400px;
}
.partInput {
  width: 365px;
  text-align: center;
}
.partBtn {
  width: 60px;
}
.partName {
  width: 100px;
  display: block;
  text-align: center;
}
.partBtn_key {
  width: 100px;
}
</style>
